import { ICoreContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

const translations: { [key: string]: { [key: string]: string } } = {
    'nb-NO': {
        'Flaske-BS': 'Stk',
        'Stk-BS': 'Stk',
        'Stk-M1': 'Krt',
        'Boks-BS': 'Stk',
        'Krt-BS': 'Stk',
        'Krt-M1': 'Krt',
        'Krt-M2': 'Krt',
        'Pose-BS': 'Stk',
        'Kanne-BS': 'Stk',
        'Fat-BS': 'Stk',
        'Glass-BS': 'Stk',
        'Rull-BS': 'Stk',
        'Brett-BS': 'Stk',
        'Brett-M1': 'Brt',
        'Brett-M2': 'Brt',
        'Tube-BS': 'Stk',
        'Sekk-BS': 'Stk',
        'Bunt-BS': 'Stk'
    },
    'en-US': {
        'Flaske-BS': 'Pcs',
        'Stk-BS': 'Pcs',
        'Stk-M1': 'Box',
        'Boks-BS': 'Pcs',
        'Krt-BS': 'Pcs',
        'Krt-M1': 'Box',
        'Krt-M2': 'Box',
        'Pose-BS': 'Pcs',
        'Kanne-BS': 'Pcs',
        'Fat-BS': 'Pcs',
        'Glass-BS': 'Pcs',
        'Rull-BS': 'Pcs',
        'Brett-BS': 'Pcs',
        'Brett-M1': 'Try',
        'Brett-M2': 'Try',
        'Tube-BS': 'Pcs',
        'Sekk-BS': 'Pcs',
        'Bunt-BS': 'Pcs'
    }
};

export function humanizeUnitOfMeasure(locale: ICoreContext | string, unitOfMeasure: string | undefined, simpleProduct?: SimpleProduct): string {
    // Check if there is a friendly UOM defined on the SimpleProduct. If so it overrides anything else.
    const friendlyUnitOfMeasure = simpleProduct?.ExtensionProperties?.find((extProp) => extProp.Key === 'FRIENDLYUOM');

    if (friendlyUnitOfMeasure && friendlyUnitOfMeasure.Value?.StringValue) {
        return friendlyUnitOfMeasure.Value.StringValue;
    }

    if (!unitOfMeasure) {
        return '';
    }

    // Check if locale is a string or an ICoreContext object
    if (typeof locale === 'object') {
        locale = locale.request.locale;
    }

    return translations[locale]?.[unitOfMeasure] || unitOfMeasure;
}
