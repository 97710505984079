import { SimpleProduct } from "@msdyn365-commerce/retail-proxy";

/**
 * Gets the default quantity to use when buying a product.
 * @param product The product to get the default quantity for.
 * @param breakoff Whether breakoff is enabled.
 * @param defaultQuantity The default quantity to use if no other quantity is found. Defaults to 1.
 * @returns The default quantity to use for the product.
 */
export function getDefaultBuyQuantity(product: SimpleProduct, breakoff: boolean, defaultQuantity: number = 1): number {
    if (breakoff) {
        return product.Behavior?.MultipleOfQuantity || defaultQuantity;
    }

    return product.Behavior?.DefaultQuantity || defaultQuantity;
}