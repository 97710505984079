import * as React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import { humanizeUnitOfMeasure } from './utils';

interface IUnitOfMeasureProps {
    context: ICoreContext;
    className?: string;
    simpleProduct?: SimpleProduct;
    unitOfMeasure?: string;
    disableTranslate?: boolean;
}

const UnitOfMeasure = (props: IUnitOfMeasureProps) => {
    const { context } = props;
    let { disableTranslate } = props;

    const className = classnames('vinhuset-unit-of-measure', props.className);

    // Check if already disabled
    if (!disableTranslate) {
        // @ts-expect-error
        if (context.app.config.humanizeUnitOfMeasure !== true) {
            disableTranslate = true;
        } else {
            // Disable if disableUnitReplace is present and not 1 or 'true'
            const disableUnitReplace = context.request.url.requestUrl.searchParams.get('disableUnitReplace');
            disableTranslate = disableUnitReplace === '' || disableUnitReplace === '1' || disableUnitReplace?.toLowerCase() === 'true';
        }
    }

    let unitOfMeasure = props.unitOfMeasure || props.simpleProduct?.DefaultUnitOfMeasure || '';
    unitOfMeasure = disableTranslate
        ? unitOfMeasure
        : humanizeUnitOfMeasure(context, unitOfMeasure);

    // Check if there is a friendly UOM defined on the SimpleProduct
    const friendlyUnitOfMeasure = props.simpleProduct?.ExtensionProperties?.find((extProp) => extProp.Key === 'FRIENDLYUOM');

    if (!disableTranslate && friendlyUnitOfMeasure && friendlyUnitOfMeasure.Value?.StringValue) {
        unitOfMeasure = friendlyUnitOfMeasure.Value.StringValue;
    }

    return (
        <span className={className}>
            {unitOfMeasure}
        </span>
    );
};

export default UnitOfMeasure;
